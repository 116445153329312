import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Topbar from "./components/topbar/Topbar";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
    NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Home from "./pages/Home/Home";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function App() {

    const [open, setOpen] = React.useState(false);

    const [message, setMessage] = React.useState("");

    const [severity, setSeverity] = React.useState();

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const {render, setForceRefresh} = Topbar();

    return (
        // <CheckConnection>
        <div
            style={{
                height: "100%",
                backgroundColor: "#FCF4F4",

                // padding: "10px 60px 0 60px",

            }}
        >
            <NotificationContainer/>
            <Stack spacing={2} sx={{width: "100%"}}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{width: "100%"}}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <div className="App">
                <div className="cont">
                    <Router>
                        <div
                            style={{
                                flex: 4,
                                width: "100%",
                                backgroundColor: "#FCF4F4"
                            }}
                        >
                            {render}

                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Home
                                            snackBar={(message) => {
                                                handleClick(message);
                                            }}
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                        />
                                    }
                                />

                                <Route path='/MPESA-organization-portal' component={() => {
                                    window.location.href = 'https://org.ke.m-pesa.com/orglogin.action?language=en';
                                    return null;
                                }}/>
                            </Routes>

                        </div>
                    </Router>
                </div>
            </div>
        </div>
        // </CheckConnection>
    );
}

export default App;
