import React, {useEffect, useState} from "react";
import "./home.css";
import MuiAlert from "@mui/material/Alert";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import modernApartment from '../../assets/mordern_apartment_1.jpg';
import whyChooseUs from '../../assets/why_choose_us.jpg';
import aboutUs from '../../assets/about_us.jpg';
import contactUs from '../../assets/contact_us.jpg';
import {EmailOutlined, LocalPhoneOutlined, SendOutlined} from "@material-ui/icons";
import {Form, Formik} from "formik";
import {LoadingButton} from "@mui/lab";
import TextField from '@mui/material/TextField';
import * as Yup from "yup";
import {ApiDetails} from "../../dummyData";
import {useMediaQuery} from "@mui/material";

export default function Home(props) {

    const matches = useMediaQuery("(max-width:1000px)");
    const matches2 = useMediaQuery("(max-width:600px)");

    //Loading Screen
    let [backDrop, setBackDrop] = useState(// <Backdrop
        //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //   open={true}
        //   onClick={null}
        // >
        //   <CircularProgress color="inherit" />
        // </Backdrop>
        <></>);

    const [name, setName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [comment, setComment] = useState("")

    const [loading, setLoading] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault();

        setLoading(true)

        if (name.toString().trim() === "" || mobileNumber.toString().trim() === "" || comment.toString().trim() === "") {
            props.snackBar({
                text: "Kindly fill in all details", type: "error",
            });

            setLoading(false)
            return;

        }

        let details = {};
        details["name"] = name;
        details["phone_number"] = mobileNumber;
        details["comment"] = comment

        console.log(details)
        console.log(typeof details)

        fetch(ApiDetails + "pegasus/visionary/extras/queries", {
            method: "POST", body: JSON.stringify(((details))),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {

                    // setName("")
                    // setComment("")
                    // setMobileNumber("")

                    props.snackBar({
                        text: "Form submitted successfully", type: "success",
                    });
                    setLoading(false)


                } else {
                    setBackDrop(<></>);
                    props.snackBar({
                        text: "Error occured. Kindly try again later", type: "error",
                    });
                    setLoading(false)

                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)

            });

    }

    return (<>
        {backDrop}

        <div
            id={"home"}
            style={{
                margin: "auto",
                height: "70vh",
                width: "90%",
                backgroundColor: "#a9cff8",

                // backgroundColor: "#FCF4F4",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "30px",
                overflow: "auto",
                display: "flex",
                justifyContent: "space-between",

            }}
        >

            <div
                style={{
                    flex: 1.25,
                    padding: "30px",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "auto",
                    webkitLineClamp: 3,

                }}
            >

                <h1
                    className={"header"}
                >ITORAMA PROPERTY MANAGEMENT AGENCY</h1>


                <p
                    className={"question"}>
                    Are you seeking stress-free property ownership?
                </p>
                <p
                    className={"paragraph"}>

                    Look no further than ITorama property management agency.
                    We are your trusted partner in property management, dedicated to maximizing your investment
                    while minimizing the hassles that come with it.
                </p>
                <p
                    className={"paragraph"}>


                    Our team of experienced professionals is committed to providing top-notch services, including
                    property maintenance, tenant screening, rent collection, and comprehensive financial reporting.
                    With ITorama property agency, you can enjoy peace of mind knowing that your properties are in
                    capable hands.
                </p>

                <p
                    className={"paragraph"}>
                    We tailor our services to meet your specific needs, whether you have a single
                    rental property or a diverse portfolio.

                </p>
                <p
                    className={"paragraph"}>


                    Let us handle the details so you can focus on what matters most to you.
                    Contact us today to discover how we can help you achieve greater returns on your real estate
                    investments.
                </p>

            </div>


            <div
                style={{
                    display: matches ? "none" : "", flex: 0.75
                }}>

                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomLeftRadius: "30px",

                        display: "flex",
                        justifyContent: "space-between",

                    }}
                    src={modernApartment}
                    loading={"lazy"}
                />

            </div>

        </div>


        <div
            id={"whyUs"}
            style={{
                paddingTop: "40px",
                margin: "auto",
                height: "70vh",
                width: "90%",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "30px",
                overflow: "auto",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px"
            }}
        >

            <div

                style={{
                    display: matches ? "none" : "", flex: 0.75
                }}>

                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomLeftRadius: "30px",

                        display: "flex",
                        justifyContent: "space-between",

                    }}
                    src={whyChooseUs}
                    loading={"lazy"}
                />

            </div>

            <div
                style={{
                    flex: 1.25, padding: "30px", flexDirection: "column", justifyContent: "center", overflow: "auto",

                }}
            >

                <h1
                    className={"header"}
                >WHY CHOOSE US ?</h1>

                <div
                    style={{
                        width: "100%", display: "flex", justifyContent: "space-between"
                    }}
                >


                    <div>
                        <h4>1. Experience and Expertise</h4>
                        <h4>2. Personalized Service</h4>
                        <h4>3. Transparency</h4>
                        <h4>4. Tenant Screening </h4>
                        <h4>5. Property Maintenance </h4>

                    </div>
                    <div
                        style={{
                            marginLeft: "10px"
                        }}
                    >

                        <h4>6. Technology and Innovation </h4>
                        <h4>7. Legal and Compliance Expertise </h4>
                        <h4>8. Client Testimonials </h4>
                        <h4>9. Customized Pricing</h4>
                    </div>

                </div>

            </div>

        </div>


        <div
            id={"about"}
            style={{

                margin: "auto",
                height: "70vh",
                width: "90%",
                backgroundColor: "#9caf80",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "30px",
                overflow: "auto",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px",
            }}
        >

            <div
                style={{
                    flex: 1.25, padding: "30px", flexDirection: "column", justifyContent: "center", overflow: "auto",

                }}
            >

                <h1
                    className={"header"}
                >ABOUT US</h1>

                <ol>
                    <li><h4>

                        Our Agency's Journey

                    </h4></li>

                    <p
                        className={"paragraph"}>

                        ITorama Property Management Agency has been a trusted name in property management since its
                        inception . Founded by Mark Kieru a seasoned real estate
                        professional with a passion for delivering exceptional service, our agency embarked on a
                        mission
                        to redefine the property management experience.


                    </p>
                    <li><h4>

                        A Legacy of Excellence


                    </h4></li>
                    <p
                        className={"paragraph"}>

                        From the very beginning, our agency set out to raise the bar in property management.
                        Mark Kieru had a vision to provide property owners with peace of mind, knowing their investments
                        were
                        in capable hands, while also ensuring tenants enjoyed a comfortable and safe living
                        environment.
                        With this commitment to excellence, we quickly gained a reputation for delivering top-notch
                        services.
                    </p>
                </ol>

            </div>


            <div

                style={{
                    display: matches ? "none" : "",

                    flex: 0.75
                }}>

                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomLeftRadius: "30px",

                        display: "flex",
                        justifyContent: "space-between",

                    }}
                    src={aboutUs}
                    loading={"lazy"}

                />

            </div>

        </div>


        <div
            id={"contact"}
            style={{
                margin: "auto",
                height: matches2 ? "" : "70vh",
                width: "90%",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "30px",
                overflow: "auto",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px"
            }}
        >

            <div

                style={{
                    display: matches ? "none" : "",

                    flex: 0.75
                }}>

                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomLeftRadius: "30px",

                        display: "flex",
                        justifyContent: "space-between",

                    }}
                    src={contactUs}
                    loading={"lazy"}

                />

            </div>

            <div
                style={{
                    flex: 1.25,
                    padding: "10px 20px",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "auto",

                }}
            >
                <h1
                    className={"header"}
                >Contact us</h1>

                <div
                    style={{
                        margin: "auto",
                        height: matches2 ? "" : "70%",
                        width: "90%",
                        display: "flex",
                        flexDirection: matches2 ? "column" : ""
                    }}
                >


                    <div
                        style={{
                            height: matches2 ? "" : "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >

                        <div

                            style={{
                                zIndex: matches2 ? 0 : 999,
                                // marginBottom: matches2 ? "" : "70%",

                            }}

                            className={"contactDetails"}
                        >

                            <h3>Call us on</h3>
                            <div
                                style={{
                                    display: "flex", marginTop: "10px",
                                }}
                            >
                                <LocalPhoneOutlined/>

                                <span> +254712292899 / +254721655268</span>
                            </div>

                            <h3>Email us on</h3>


                            <div
                                style={{
                                    display: "flex", marginTop: "10px",
                                    // marginBottom: matches2 ? "20px" : "",

                                }}
                            >
                                <EmailOutlined/>

                                <span> mkieru55@gmail.com </span>
                            </div>

                        </div>

                    </div>


                    <div
                        className={"getInTouch"}


                        style={{
                            marginLeft: matches2 ? "0px" : "-50px",
                            marginTop: matches2 ? "30px" : "0px",
                            border: "2px solid #bbbbbb",
                            borderRadius: "5px",
                            width: "100%",
                            overflow: "auto"
                        }}
                    >
                        <div
                            style={{
                                padding: matches2 ? "0 10px 0 10px" : "0 10px 0 70px",
                                overflow: "hidden"
                            }}
                        >

                               <span>


                                <h4
                                    className={"header"}

                                    style={{
                                        textAlign: "center", alignContent: "center",
                                    }}
                                >Get in touch</h4>

                                   <div>

                                     <form action="submit">

                    <div
                        style={{
                            display: "flex", flexDirection: "column",
                        }}
                    >

                        <TextField
                            required
                            id="outlined-required"
                            label="Full names"
                            onChange={(value) => {
                                setName(value.target.value)
                            }}
                        />

                        <TextField
                            required
                            id="outlined-required"
                            label="Mobile number"
                            type={"number"}
                            style={{marginTop: "10px"}}
                            onChange={(value) => {
                                setMobileNumber(value.target.value)
                            }}
                        />

                        <TextField
                            id="outlined-multiline-static"
                            label="Talk to us ... "
                            multiline
                            rows={3}
                            style={{
                                marginTop: "10px"
                            }}

                            onChange={(value) => {
                                setComment(value.target.value)
                            }}
                        />
                    </div>

                                         {/*{captcha}*/}
                                         <div
                                             style={{
                                                 width: "100%", display: "flex", justifyContent: "center",
                                             }}
                                         >

                        <LoadingButton
                            type={"submit"}
                            endIcon={<SendOutlined/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            style={{
                                marginTop: "10px", marginBottom: "10px",
                            }}

                            onClick={(e) => {
                                onSubmit(e)
                            }}
                        >
                            Send
                        </LoadingButton>
                    </div>
             </form>
                                   </div>
                               </span>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </>);
}
