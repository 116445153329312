import React, {useEffect, useState} from "react";
import "./topbar.css";
import {Avatar, useMediaQuery} from "@mui/material";
import "react-internet-meter/dist/index.css";
import logo from '../../assets/logo-no-background.png';

import {styled, alpha} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {CallOutlined, HomeOutlined, InfoOutlined} from "@material-ui/icons";
import {LoginOutlined, PersonAddAltOutlined, QuestionMarkOutlined} from "@mui/icons-material";


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function Topbar(props) {
    const matches = useMediaQuery("(max-width:1000px)");

    const [myNav, setMyNav] = useState("")


    // window.onscroll = function () {
    //     if(myNav === undefined){return }
    //
    //     if (document.body.scrollTop >= 200) {
    //         myNav.classList.add("nav-colored");
    //         myNav.classList.remove("nav-transparent");
    //     } else {
    //         myNav.classList.add("nav-transparent");
    //         myNav.classList.remove("nav-colored");
    //     }
    // };


    useEffect(() => {
        setMyNav(document.getElementById('mynav'))

    }, [])

    function scrollToElement(id) {
        var el = document.getElementById(id);
        el.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return {
        render: (
            <div id={"mynav"} className="topbar">
                <div
                    style={{
                        display: !matches ? "" : "flex",
                        justifyContent: "space-between"
                    }}
                    className="topbarWrapper">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            style={{

                                width: matches ? "100px" : "160px",
                                height: matches ? "50px" : "80px",
                            }}
                            src={logo}
                        />
                    </div>

                    <div

                        style={{
                            margin: "auto",
                            width: "30vw",
                            display: matches ? "none" : "flex",
                            justifyContent: "space-between",
                            textAlign: "center",
                        }}
                    >
                    <span className={"map"}
                          onClick={() => {
                              // window.location.href = '#home'
                              scrollToElement("home")
                          }}
                    >Home</span>
                        <div className="wrapper">
                            <div className="dot"></div>
                        </div>
                        <span className={"map"}

                              onClick={() => {
                                  // window.location.href = '#whyUs'
                                  scrollToElement("whyUs")

                              }}
                        >Why us ?</span>
                        <div className="wrapper">
                            <div className="dot"></div>
                        </div>
                        <span className={"map"}
                              onClick={() => {
                                  // window.location.href = '#whyUs'
                                  scrollToElement("about")
                              }}

                        >About us</span>
                        <div className="wrapper">
                            <div className="dot"></div>
                        </div>

                        <span className={"map"}

                              onClick={() => {
                                  // window.location.href = '#whyUs'
                                  scrollToElement("contact")
                              }}

                        > Contact us</span>
                    </div>

                    <div

                        style={{
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                display: matches ? "none" : "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginRight: "20px"
                            }}
                        >

                            <Button variant="contained"
                                    onClick={() => {
                                        window.open(
                                            'https://pegasus-pms.co.ke',
                                            '_blank' // <- This is what makes it open in a new window.
                                        );
                                    }}
                            >Login</Button>
                        </div>
                        <div
                            style={{
                                display: matches ? "none" : "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}
                        >
                            <Button variant="outlined"
                                    onClick={() => {
                                        window.open(
                                            'https://pegasus-pms.co.ke/signup',
                                            '_blank' // <- This is what makes it open in a new window.
                                        );
                                    }}
                            >Sign Up</Button>
                        </div>

                        <div
                            style={{
                                display: !matches ? "none" : "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}
                        >
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon/>}
                            >
                                Options
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        // window.location.href = '#home'
                                        scrollToElement("home")
                                        handleClose()
                                    }}
                                    disableRipple>
                                    <HomeOutlined/>
                                    Home
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        // window.location.href = '#whyUs'
                                        scrollToElement("whyUs")
                                        handleClose()
                                    }}
                                    disableRipple>
                                    <QuestionMarkOutlined/>
                                    Why choose us
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        // window.location.href = '#whyUs'
                                        scrollToElement("about")
                                        handleClose()
                                    }}
                                    disableRipple>
                                    <InfoOutlined/>
                                    About us
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        // window.location.href = '#whyUs'
                                        scrollToElement("contact")
                                        handleClose()
                                    }}

                                    disableRipple>
                                    <CallOutlined/>
                                    Contact us
                                </MenuItem>
                                <Divider sx={{my: 0.5}}/>

                                <MenuItem
                                    onClick={() => {
                                        window.open(
                                            'https://pegasus-pms.co.ke',
                                            '_blank' // <- This is what makes it open in a new window.
                                        );
                                        handleClose()
                                    }}

                                    disableRipple>
                                    <LoginOutlined/>
                                    Login
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        window.open(
                                            'https://pegasus-pms.co.ke/signup',
                                            '_blank' // <- This is what makes it open in a new window.
                                        );
                                        handleClose()
                                    }}


                                    disableRipple>
                                    <PersonAddAltOutlined/>
                                    Sign up
                                </MenuItem>


                            </StyledMenu>

                        </div>
                    </div>

                </div>
            </div>),
    };
}
